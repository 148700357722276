"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCustomer = useCustomer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var Sentry = _interopRequireWildcard(require("@sentry/react"));
var _useCustomer = require("../../hooks/useCustomer");
var _contexts = require("../../contexts");
var _react2 = require("react");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function useCustomer() {
  const api = (0, _contexts.useCommerceAPI)();
  const self = (0, _useCustomer.useCustomer)();
  // @ts-ignore
  const {
    customer,
    setCustomer
  } = (0, _react2.useContext)(_contexts.CustomerContext);

  /**
   * Update customer information.
   *
   * @param {object} data
   * @param {string} data.email
   * @param {string} data.firstName
   * @param {string} data.lastName
   * @param {string} data.phone
   * @param {string} data.c_newsletter
   * @param {string} data.c_aboAlerts
   * @param {string} data.c_decalcifyAlerts
   * @param {string} data.c_preferredPaymentInstrument
   * @param {string} data.salutation
   * @param {string} data.birthday
   * @param {string} data.phoneHome
   * @param {string} data.phoneBusiness
   * @param {string} data.phoneMobile
   */
  function updateCustomer(_x) {
    return _updateCustomer.apply(this, arguments);
  }
  /**
   * Log in customer account.
   *
   * @param {object} credentials
   * @param {string} credentials.email
   * @param {string} credentials.password
   */
  function _updateCustomer() {
    _updateCustomer = (0, _asyncToGenerator2.default)(function* (data) {
      const body = {
        salutation: data.salutation,
        firstName: data.firstName,
        lastName: data.lastName,
        birthday: data.birthday,
        phoneHome: data.phoneHome,
        phoneMobile: data.phoneMobile,
        phoneBusiness: data.phoneBusiness,
        c_newsletter: data.c_newsletter,
        c_newsletterSource: data.c_newsletterSource,
        c_ignoreLifecycle: typeof data.ignoreLifecycle === 'undefined' ? false : true,
        c_aboAlerts: data.c_aboAlerts,
        c_decalcifyAlerts: data.c_decalcifyAlerts,
        c_preferredPaymentInstrument: data.c_preferredPaymentInstrument,
        email: data.email,
        login: data.email
      };

      // @ts-ignore
      const response = yield api.shopperCustomers.updateCustomer({
        body,
        parameters: {
          customerId: customer.customerId
        }
      });
      if (response.detail && response.title && response.type) {
        throw new Error(response.detail);
      }
      yield self.getCustomer();
    });
    return _updateCustomer.apply(this, arguments);
  }
  function login(_x2) {
    return _login.apply(this, arguments);
  }
  function _login() {
    _login = (0, _asyncToGenerator2.default)(function* (credentials) {
      // @ts-ignore
      const skeletonCustomer = yield api.auth.login(credentials);
      if (skeletonCustomer.authType === 'guest') {
        setCustomer(skeletonCustomer);
      } else {
        // @ts-ignore
        const customer = yield api.shopperCustomers.getCustomer({
          parameters: {
            customerId: skeletonCustomer.customerId
          }
        });

        // check if customer login is different than the email from loginForm
        if (credentials.email.toLowerCase() !== customer.login.toLowerCase()) {
          const additionalData = {
            'error info': {
              customerLoginEmail: credentials.email,
              customerObjectEmail: customer.login
            }
          };
          Sentry.withScope(scope => {
            scope.setContext('Login Error', additionalData);
            Sentry.captureMessage(`AUTH EVENT: LoginForm email and Customer object login mismatch`, 'error');
          });
          throw new Error('SLAS Error');
        }
        setCustomer(customer);
      }
    });
    return _login.apply(this, arguments);
  }
  const customUseCustomer = _objectSpread(_objectSpread({}, self), {}, {
    customer,
    updateCustomer,
    login,
    get authType() {
      return (customer === null || customer === void 0 ? void 0 : customer.authType) || 'guest';
    },
    /** Returns the customer's saved addresses with the 'preferred' address in the first index */
    get addresses() {
      if (!(customer !== null && customer !== void 0 && customer.addresses)) {
        return [];
      }
      customer.addresses.sort((a, b) => {
        // @ts-ignore
        return new Date(a.creationDate) - new Date(b.creationDate);
      });
      const sortedList = customer.addresses.reduce((acc, curr) => {
        return curr.preferred ? [curr, ...acc] : [...acc, curr];
      }, []);
      return sortedList;
    },
    /** Returns the customer's saved paymentInstruments with the 'preferred' address in the first index */
    get paymentInstruments() {
      if (!(customer !== null && customer !== void 0 && customer.paymentInstruments)) {
        return [];
      }
      let preferredPaymentInstrument = null;
      const sortedPaymentInstrument = customer.paymentInstruments.filter(paymentInstrument => {
        if (paymentInstrument.paymentInstrumentId === (customer.c_preferredPaymentInstrument || '')) {
          preferredPaymentInstrument = paymentInstrument;
          return false;
        }
        return true;
      });
      if (preferredPaymentInstrument) {
        sortedPaymentInstrument.unshift(preferredPaymentInstrument);
        return sortedPaymentInstrument;
      }
      return customer.paymentInstruments;
    }
  });
  return customUseCustomer;
}